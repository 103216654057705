5<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else>
            <employee-profile :employee="employee" />
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.email") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "workEmail") || "-" }}
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.staff_number") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "employment.staffNumber") || "-" }}
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.branch") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "employment.branch.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.unit") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "employment.unit.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.team") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "employment.team.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.overview.job_info") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <span v-else class="grey--text body-2">
            {{ $helpers.get(employee, "employment.jobInfo.name") || "-" }}
          </span>
        </v-col>
        <v-col cols="12">
          <v-chip small class="mr-1 mb-1"  v-for="(item,index) in definitions" :key="index">
            {{item}}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE } from "../query";

  export default {
    name: "EmployeeOverview",
    data: () => ({
      loading: false,
      employee: null
    }),
    computed: {
      definitions(){
        return this.$helpers.get(this.employee, "employment.jobInfo.definitions") || []
      }
    },
    methods: {
      async fetchEmployeeOverview() {
        this.loading = true;
        await this.$apollo
          .query({
            query: EMPLOYEE,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee;
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeOverview();
    }
  };
</script>

<style scoped></style>
